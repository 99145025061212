import useSettingsStore from '@store/settingsStore'
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  SortingState,
  Table as TanstackTable,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import useBreakpoints from 'hooks/useBreakpoints'
import { useTheme } from 'next-themes'
import SortingIcon from 'public/icons/sorting-icon.svg'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { cn } from 'utils/classnames'
import EmptyState from '../EmptyState'
import Loader from '../RfxLoader'
import TableRowAccordian from '@components/TableRowAccordian'

interface FilterComponentProps<TData extends RowData> {
  column: Column<TData>
  table: TanstackTable<TData>
  title: string
  options?: string[]
}

interface CustomFilterMeta<TData extends RowData> {
  filterComponent: ({
    column,
    table,
    title,
  }: FilterComponentProps<TData>) => JSX.Element
  filterTitle: string
  dropdownFilterOptions: string[]
}

interface TableProps<TData extends RowData> {
  rows: TData[]
  columns: ColumnDef<TData>[]
  rowsPerPage?: number
  className?: string
  emptyTable?: ReactNode
  hidePagination?: boolean
  thClassName?: string
  hideHeaderGroup?: boolean
  pagination?: {
    page: number
    setPage: (page: number) => void
    totalPages?: number
  }
  paginationVariant?: 'secondary'
  stickyRowData?: { [id: string]: { value: ReactNode; align?: any } }
  onBottomReached?: (e: HTMLDivElement) => void
  fetching?: boolean
  handleRowClick?: (row: TData) => void
  tableClassName?: string
  rowClassName?: string
  hideHeader?: boolean
  colSpanColumns?: { [columnIndex: number]: number }
  theadClassName?: string
  stickyRowCss?: string
  hasHoverState?: boolean
  mobileVisibleColumnsIds?: string[]
  mobileHiddenColumnsIds?: string[]
  actionColumnsIds?: string[]
  mobileVisibleRowClassName?: string
  mobileVisibleColClassName?: string
  mobileHiddenRowClassName?: string
  mobileHiddenColClassName?: string
  colsToRightAlign?: string[]
}

const applyPadding = (idx: number, length: number) => {
  return idx === 0 || idx === length - 1
}

const isFirstColumnFixed = (
  containerWidth: number,
  tableWidth: number,
  columnIndex: number,
): boolean => {
  return columnIndex === 0 && tableWidth > containerWidth
}

export const Table = <TData extends RowData>({
  rows,
  columns,
  onBottomReached,
  rowsPerPage = 50,
  thClassName,
  hideHeaderGroup = true,
  handleRowClick,
  tableClassName,
  rowClassName,
  hideHeader,
  theadClassName,
  stickyRowCss,
  mobileVisibleColumnsIds,
  mobileHiddenColumnsIds,
  actionColumnsIds,
  mobileVisibleRowClassName,
  mobileVisibleColClassName,
  mobileHiddenRowClassName,
  mobileHiddenColClassName,
  colsToRightAlign,
  ...props
}: TableProps<TData>) => {
  const { theme } = useTheme()
  const { below } = useBreakpoints()
  const isPro = useSettingsStore((state) => state.isPro())

  const containerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)

  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [tableWidth, setTableWidth] = useState<number>(0)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: rows,
    columns,
    state: {
      columnFilters,
      sorting,
    },
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const { paddingX, tableHeaderHeight, tableRowHeight, stickyRowHeight } =
    useMemo(() => {
      const defaultPaddingX = below.lg ? 'px-4' : 'px-6'
      const defaultHeight = below.lg ? 'h-[40px]' : 'h-[48px]'
      const proHeight = 'h-[32px]'

      return {
        paddingX: isPro ? 'px-4' : defaultPaddingX,
        tableHeaderHeight: isPro
          ? proHeight
          : below.lg
            ? 'h-[24px]'
            : 'h-[40px]',
        tableRowHeight: isPro ? proHeight : defaultHeight,
        stickyRowHeight: isPro ? proHeight : defaultHeight,
      }
    }, [below.lg, isPro])

  const applyHoverState = !!(handleRowClick || props.hasHoverState)

  const updateWidths = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth)
    }
  }

  useEffect(() => {
    const containerElement = containerRef.current

    const resizeObserver = new ResizeObserver(() => {
      updateWidths()
    })

    if (containerElement) {
      resizeObserver.observe(containerElement)
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
  }, [])

  const getHeader = (colID: string) => {
    const tableHeader = table
      .getHeaderGroups()
      .find(({ headers }) =>
        headers.some((header) => header.column.id === colID),
      )

    if (!tableHeader) {
      return undefined
    }

    const header = tableHeader.headers.find(
      (header) => header.column.id === colID,
    )

    return header
  }

  return (
    <>
      {!hideHeaderGroup && (
        <div className="flex justify-between">
          <div className="mb-3 flex h-10 gap-4">
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers.map((header, index) => {
                const Filter = (
                  header.column.columnDef?.meta as CustomFilterMeta<TData>
                )?.filterComponent
                const filterTitle = (
                  header.column.columnDef?.meta as CustomFilterMeta<TData>
                )?.filterTitle
                const dropdownFilterOptions = (
                  header.column.columnDef?.meta as CustomFilterMeta<TData>
                )?.dropdownFilterOptions
                return (
                  header.column.getCanFilter() &&
                  Filter && (
                    <div key={index} className="flex">
                      <Filter
                        column={header.column}
                        options={dropdownFilterOptions}
                        table={table}
                        title={filterTitle}
                      />
                    </div>
                  )
                )
              }),
            )}
          </div>
        </div>
      )}
      <div
        className={cn(
          'flex h-full w-full overflow-x-auto',
          'flex-col sm:flex-row',
          props.className,
        )}
      >
        <div
          className={cn('w-full overflow-auto')}
          onScroll={(e) =>
            onBottomReached && onBottomReached(e.target as HTMLDivElement)
          }
          ref={containerRef}
        >
          <table
            ref={tableRef}
            className={cn(
              'w-max min-w-full table-fixed overflow-x-auto',
              mobileVisibleColumnsIds &&
                mobileVisibleColumnsIds.length > 0 &&
                'max-[1280px]:hidden',
              tableClassName,
            )}
          >
            <thead
              className={cn(
                "sticky top-0 z-30 bg-th-bkg-1 font-body text-xs font-normal capitalize text-th-fgd-2 before:absolute before:bottom-0 before:border-b before:border-th-input-border before:content-['']",
                hideHeader && 'hidden',
                tableHeaderHeight,
                theme === 'Stargazer'
                  ? 'bg-th-bkg-5 before:w-full'
                  : 'noise bg-th-bkg-1',
                theadClassName,
              )}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={cn(below.lg && 'relative')}>
                  {headerGroup.headers.map((header, i) => (
                    <th
                      key={header.id}
                      align={(header.column.columnDef.meta as any)?.align}
                      className={cn(
                        'h-full w-fit min-w-[100px] whitespace-nowrap font-body text-xs font-normal uppercase text-th-fgd-2',
                        applyPadding(i, headerGroup.headers.length) && paddingX,
                        isFirstColumnFixed(containerWidth, tableWidth, i)
                          ? theme === 'Stargazer'
                            ? 'sticky left-0 z-10 h-full bg-th-bkg-5 before:absolute before:bottom-0 before:left-0 before:h-full before:w-full before:border-b before:border-th-input-border before:content-[""]'
                            : 'noise sticky left-0 z-10 bg-th-bkg-1'
                          : '',
                        thClassName && thClassName,
                      )}
                      colSpan={props.colSpanColumns?.[i] || 1}
                    >
                      <div
                        className={
                          'flex items-center justify-center' +
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <span className="flex items-center gap-2">
                          {flexRender(
                            header.column?.columnDef.header,
                            header.getContext(),
                          )}{' '}
                          {{
                            asc: <SortingIcon />,
                            desc: <SortingIcon />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="text-sm">
              {table.getRowModel().rows.map((row: any) => (
                <tr
                  key={row.id}
                  className={cn(
                    'relative text-center',
                    applyHoverState && 'gmlist-row cursor-pointer',
                    tableRowHeight,
                    rowClassName,
                  )}
                  onClick={() => handleRowClick && handleRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell: any, i: number) => (
                    <td
                      key={cell.id}
                      align={(cell.column.columnDef.meta as any)?.align}
                      className={cn(
                        applyPadding(i, row.getVisibleCells().length) &&
                          paddingX,
                        isFirstColumnFixed(containerWidth, tableWidth, i) &&
                          (theme === 'Stargazer'
                            ? 'sticky left-0 z-10 bg-th-bkg-5'
                            : 'noise sticky left-0 z-10 bg-th-bkg-1'),
                      )}
                      colSpan={props.colSpanColumns?.[i] || 1}
                    >
                      {flexRender(
                        cell.column?.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {props.fetching && (
                <div className="flex h-full w-full items-center justify-center">
                  <Loader />
                </div>
              )}
              {props.stickyRowData && rows.length ? (
                <tr
                  className={cn(
                    'sticky bottom-0 z-40 text-center',
                    stickyRowHeight,
                    stickyRowCss,
                  )}
                >
                  {Object.values(props.stickyRowData).map((data, idx) => (
                    <td
                      key={idx}
                      align={data.align || 'center'}
                      className={cn(
                        applyPadding(idx, rows.length) && paddingX,
                        isFirstColumnFixed(containerWidth, tableWidth, idx) &&
                          (theme === 'Stargazer'
                            ? 'sticky left-0 z-10 bg-th-bkg-5'
                            : '!noise sticky left-0 z-10 bg-th-bkg-1'),
                      )}
                    >
                      {data.value}
                    </td>
                  ))}
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>

          <div
            className={cn(
              'h-full w-full overflow-y-auto lg:hidden',
              !mobileVisibleColumnsIds &&
                !mobileHiddenColumnsIds?.length &&
                'hidden',
            )}
          >
            {table.getRowModel().rows.map((row) => {
              const mobileVisibleColumns = row
                .getVisibleCells()
                .filter((cell) =>
                  mobileVisibleColumnsIds?.includes(cell.column.id),
                )

              const mobileHiddenColumns = row
                .getVisibleCells()
                .filter((cell) =>
                  mobileHiddenColumnsIds?.includes(cell.column.id),
                )

              const mobileActionColumns = row
                .getVisibleCells()
                .filter((cell) => actionColumnsIds?.includes(cell.column.id))

              return (
                <TableRowAccordian
                  className="py-2"
                  HiddenContent={
                    <div className="flex">
                      <div
                        className={cn(
                          'mt-2 grid flex-1 grid-cols-4 items-start gap-2',
                          `grid-cols-${mobileVisibleColumns.length}`,
                          mobileHiddenRowClassName,
                        )}
                      >
                        {mobileHiddenColumns.map((col) => {
                          const header = getHeader(col.column.id)

                          return (
                            <div
                              className={cn(
                                'flex flex-col gap-1',
                                colsToRightAlign?.includes(col.column.id) &&
                                  'items-end',
                                mobileHiddenColClassName,
                              )}
                            >
                              {header && (
                                <span className="!text-xxs font-normal uppercase text-th-fgd-2">
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </span>
                              )}
                              <span className="!font-medium text-th-fgd-1">
                                {flexRender(
                                  col.column?.columnDef.cell,
                                  col.getContext(),
                                )}
                              </span>
                            </div>
                          )
                        })}
                      </div>
                      <div className="w-6" />
                    </div>
                  }
                  VisibleContent={
                    <div
                      className={cn(
                        'grid items-start justify-between gap-2',
                        `grid-cols-${mobileVisibleColumns.length}`,
                        mobileVisibleRowClassName,
                      )}
                    >
                      {mobileVisibleColumns.map((col) => {
                        const header = getHeader(col.column.id)

                        return (
                          <div
                            className={cn(
                              'flex flex-col gap-1',
                              colsToRightAlign?.includes(col.column.id) &&
                                'items-end',
                              mobileVisibleColClassName,
                            )}
                          >
                            {header && (
                              <span className="text-xxs font-normal uppercase text-th-fgd-2">
                                {flexRender(
                                  header?.column.columnDef.header,
                                  header?.getContext(),
                                )}
                              </span>
                            )}
                            <span className="!font-medium text-th-fgd-1">
                              {flexRender(
                                col.column?.columnDef.cell,
                                col.getContext(),
                              )}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  }
                  ActionContent={
                    <div className="flex w-full justify-between pt-2">
                      {mobileActionColumns?.map((col) => (
                        <div className="w-full">
                          {flexRender(
                            col.column?.columnDef.cell,
                            col.getContext(),
                          )}
                        </div>
                      ))}
                    </div>
                  }
                />
              )
            })}
          </div>
          {table.getRowModel().rows.length === 0 && !props.fetching && (
            <>
              {props.emptyTable ? (
                <div className="mt-10 flex items-center justify-center md:mt-0 md:h-[calc(100%-50px)]">
                  {props.emptyTable}
                </div>
              ) : (
                <div className="mt-10 flex items-center justify-center md:h-[calc(100%-150px)]">
                  <EmptyState text={'No Rows found'} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
